<div mat-dialog-title class="violation-dialog-title">
  <p class="fw-600">New Violation Form</p>
  <button mat-button class="save" (click)="onSave()">Save & Exit</button>
</div>
<mat-dialog-content class="violation-dialog-content">
  <form [formGroup]="form">
    <div class="w-40 mb-20px">
      <app-roam-datepicker
        name="Select the date of violation"
        formControlName="date"
      ></app-roam-datepicker>

      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('date')?.touched && form.get('date')?.hasError('required')
          "
        >
          Date is required
        </p>
      </mat-error>
    </div>

    <div
      class="association-account-container"
      style="display: flex; flex-wrap: wrap"
    >
      <div
        *ngIf="userRole === 'manager'"
        class="w-40 mb-20px"
        style="flex: 1; margin-right: 20px"
      >
        <app-roam-select
          placeholder="Association"
          [items]="associationsList"
          className="radius-10"
          formControlName="propertyId"
          [searchable]="true"
          (selectedItem)="selectedAssociationChange($event)"
        ></app-roam-select>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('propertyId')?.touched &&
              form.get('propertyId')?.hasError('required')
            "
          >
            Association is required
          </p>
        </mat-error>
      </div>

      <div class="w-40 mb-20px" style="flex: 1">
        <app-roam-select
          placeholder="Address"
          [items]="unitsList"
          className="radius-10"
          formControlName="unitId"
          [searchable]="true"
        ></app-roam-select>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('unitId')?.touched &&
              form.get('unitId')?.hasError('required')
            "
          >
            Address is required
          </p>
        </mat-error>
      </div>
    </div>

    <div class="mb-20px">
      <mat-label class="fw-400"> Violation category </mat-label>
      <div class="category mt-5px">
        <app-shortcut-menu-card
          *ngFor="let item of violationsCategories"
          [shortcut]="item"
          (click)="borderColorChange(item)"
        ></app-shortcut-menu-card>
      </div>

      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('category')?.touched &&
            form.get('category')?.hasError('required')
          "
        >
          Violation category is required
        </p>
      </mat-error>
    </div>

    <div
      class="association-account-container"
      style="display: flex; flex-wrap: wrap"
    >
      <div
        class="w-40 mb-20px"
        [ngClass]="{ 'flex-1 mr-20px': userRole === 'manager' }"
      >
        <app-roam-select
          placeholder="Violation type"
          [items]="violationsType"
          className="radius-10"
          formControlName="type"
          [searchable]="true"
          (selectedItem)="selectedTypeChange($event)"
        ></app-roam-select>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('type')?.touched &&
              form.get('type')?.hasError('required')
            "
          >
            Violation type is required
          </p>
        </mat-error>
      </div>

      <div *ngIf="userRole === 'manager'" class="w-40 mb-20px" style="flex: 1">
        <app-roam-select
          placeholder="Case status"
          [items]="statusList"
          className="radius-10"
          formControlName="status"
          [searchable]="true"
        ></app-roam-select>

        <mat-error>
          <p
            class="roam-error-message"
            *ngIf="
              form.get('status')?.touched &&
              form.get('status')?.hasError('required')
            "
          >
            Status is required
          </p>
        </mat-error>
      </div>
    </div>

    <div class="mb-20px stage-deadline-container">
      <mat-label class="fw-400">
        Please review violation paragraph below. This will be sent out in a
        letter to the house in question.
      </mat-label>

      <a class="gpt-prompter c-pointer" (click)="openChatGPT = !openChatGPT">
        <img [src]="icon.roamAiBlack.src" [alt]="icon.roamAiBlack.alt">
      </a>

      <div>
        <mat-form-field appearance="outline" class="white-bg w-100">
        <textarea
          matInput
          rows="3"
          cdkTextareaAutosize
          placeholder="Paragraph text"
          formControlName="text"
        ></textarea>
        </mat-form-field>
        <div *ngIf="openChatGPT">
          <app-ask-roam-input
            [requestText]="form.get('text')?.value"
            (onAppendText)="appendTextToForm($event)"></app-ask-roam-input>
        </div>
      </div>
      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('text')?.touched && form.get('text')?.hasError('required')
          "
        >
          Paragraph text is required
        </p>
      </mat-error>
    </div>

    <div>
      <mat-label class="fw-400"> Attachments </mat-label>

      <div *ngIf="files.length < 1" class="attachment c-pointer">
        <input type="file" id="uploadFile" (change)="selectedFile($event)" />
        <p>
          <span>Drag here</span> or
          <button
            type="button"
            mat-button
            class="save"
            (click)="openFileUpload('uploadFile')"
          >
            <img src="/assets/svg/upload-icon-black.svg" alt="Upload Icon" />
            Upload File
          </button>
        </p>
      </div>

      <div *ngIf="files.length > 0" class="attachment__view">
        <input type="file" id="uploadFile" (change)="selectedFile($event)" />
        <ng-container *ngFor="let file of files; let _fileIndex = index">
          <div class="attachment__view__file">
            <img
              [src]="
                file.type.includes('image')
                  ? '/assets/svg/image-icon.svg'
                  : '/assets/svg/file-icon.svg'
              "
              alt="Image Icon"
              style="margin-right: 10px"
            />
            <span>{{ file.name }}</span>
            <span class="vertical-line"></span>
            <img
              src="/assets/svg/trash-red-icon.svg"
              alt="Trash Icon"
              class="c-pointer"
              (click)="deleteFile(_fileIndex)"
            />
          </div>
        </ng-container>

        <button
          mat-button
          class="close-btn"
          style="color: rgba(145, 70, 106, 1)"
          (click)="openFileUpload('uploadFile')"
        >
          <img
            src="/assets/svg/plus-icon.svg"
            alt="Plus Icon"
            style="padding-right: 10px"
          />
          Add file
        </button>
      </div>

      <mat-error>
        <p
          class="roam-error-message"
          *ngIf="
            form.get('uploadFile')?.touched &&
            form.get('uploadFile')?.hasError('required')
          "
        >
          Upload attachment is required
        </p>
      </mat-error>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="violation-dialog-actions">
  <button mat-button class="close-btn" mat-dialog-close>Cancel</button>
  <button
    mat-button
    *ngIf="userRole === 'tenant'"
    class="continue-btn"
    (click)="tenantSubmit()"
  >
    Submit
  </button>
  <ng-container *ngIf="false">
  <button
    mat-button
    *ngIf="userRole === 'manager'"
    class="continue-btn"
    (click)="managerPreview()"
  >
    Preview
  </button>
  </ng-container>
  <button
    mat-button
    *ngIf="userRole === 'manager'"
    class="continue-btn"
    (click)="managerSubmit()"
  >
    Save & new
  </button>
</mat-dialog-actions>
