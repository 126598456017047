import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IShortCut } from "@app/core/components/utils/data";
import { ViolationService } from "@app/shared/services/violation.service";
import { Subscription } from "rxjs";
import { ViolationModel } from "../../utils/model";
import { iconLib } from "@app/core/const/roam-icon";
import { AuthStore } from "@app/core/auth";

@Component({
  selector: "app-new-violation",
  templateUrl: "./new-violation.component.html",
  styleUrls: ["./new-violation.component.scss"],
})
export class NewViolationComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public user = inject(AuthStore).getAuthUser();
  public userRole: string | undefined = "";
  public violationsCategories: ViolationModel[] = [];
  public violationsType: any[] = [];
  public associationsList: any[] = [];
  public selectedAssociation: any;
  public selectedType: any;
  public unitsList: any[] = [];
  public files: File[] = [];
  public openChatGPT: boolean = false;
  public icon = iconLib;
  public statusList: any[] = [
    {
      name: "Pending review",
      id: 0,
    },
    {
      name: "Open and ongoing",
      id: 1,
    },
    {
      name: "Resolved and closed",
      id: 2,
    },
    {
      name: "Placed on Hold by board or developer",
      id: 3,
    },
    {
      name: "Statute of Limitations reached",
      id: 4,
    },
    {
      name: "Case deleted",
      id: 9,
    },
  ];

  private subscribers: Subscription[] = [];

  constructor(
    private violationsService: ViolationService,
  ) {}

  ngOnInit(): void {
    this.userRole = this.user?.role;

    this.initForm();
    this.initValidator();
    this.getViolationCategoryService();
    this.getAssociationsService();
  }

  private initForm(): void {
    this.form = new FormGroup({
      propertyId: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      unitId: new FormControl(null, Validators.required),
      date: new FormControl(new Date(), Validators.required),
      type: new FormControl(null, Validators.required),
      text: new FormControl(null, Validators.required),
      uploadFile: new FormControl(null),
      status: new FormControl(1, Validators.required),
    });
  }

  private initValidator(): void {
    if (this.userRole === "manager") {
      this.form.get("type")?.clearValidators();
      this.form.get("uploadFile")?.clearValidators();
      this.form.updateValueAndValidity();
      return;
    }

    this.form.get("propertyId")?.clearValidators();
    this.form.get("unitId")?.clearValidators();
    this.form.get("status")?.clearValidators();
    this.form.updateValueAndValidity();
  }

  private getViolationCategoryService(): void {
    const subs = this.violationsService.getCategories().subscribe(resp => {
      this.violationsCategories = resp;
    });

    this.subscribers.push(subs);
  }

  private getAssociationsService(): void {
    const subs = this.violationsService.getAssociations().subscribe(resp => {
      this.associationsList = resp.properties;
    });
    this.subscribers.push(subs);
  }

  private getUnitsService(associationId: string): void {
    const subs = this.violationsService
      .getUnitsByAssociationId(associationId)
      .subscribe(resp => {
        this.unitsList = resp.units;
      });
    this.subscribers.push(subs);
  }
  private getViolationTypeService(): void {
    const subs = this.violationsService
      .getCategoriesById(this.form.get("category")?.value)
      .subscribe(resp => {
        this.violationsType = resp;
      });
    this.subscribers.push(subs);
  }

  public openFileUpload(id: string): void {
    document?.getElementById(id)?.click();
  }

  public selectedFile(event: any): void {
    const File = (event.target as HTMLInputElement).files;
    this.files.push(File![0]);
  }

  public deleteFile(index: number): void {
    this.files.splice(index, 1);
  }

  public borderColorChange(data: IShortCut): void {
    this.violationsCategories.forEach((menus: IShortCut, index: number) => {
      this.violationsCategories[index].focus = menus.id === data.id;
    });

    this.form.get("category")?.setValue(data.id);
    this.getViolationTypeService();

    if (this.form.get("type")?.value) {
      this.form.get("type")?.setValue(null);
    }
  }
  public selectedAssociationChange(data: any): void {
    this.selectedAssociation = data;
    this.getUnitsService(data);
  }

  public openGptDialog(): void {
    this.openChatGPT = true;
  }

  public appendTextToForm(response: string): void {
    const text = this.form.get("text")?.value;
    this.form.get("text")?.setValue(text + "\r\n\r\n" + response);
  }

  public selectedTypeChange(data: any): void {
    this.selectedType = data;
    const selectedTypeObject = this.violationsType.find(
      item => item.id === this.selectedType
    );
    this.form.get("text")?.setValue(selectedTypeObject?.text);
  }
  public tenantSubmit(): void {
    console.log("tenantSubmit");
  }
  public managerPreview(): void {
    console.log("managerPreview");
  }
  public managerSubmit(): void {
    console.log("managerSubmit");
    this.form.markAllAsTouched();

    // if (!this.form.valid) {
    //   console.log('errors', this.form.errors)
    //   return;
    // }

    this.submitViolation();
    // Write code here after the form is valid
  }

  onSave() {
    console.log("onSave");
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    // this.submitViolation()
  }

  submitViolation(): void {
    const request = this.violationsService.createViolation({
      date: this.form.controls["date"].value,
      propertyId: this.selectedAssociation,
      unitId: this.form.controls["unitId"].value,
      categoryId: this.form.controls["category"].value,
      type: this.form.controls["type"].value,
      status: this.form.controls["status"].value,
      text: this.form.controls["text"].value,
      files: this.files ?? [],
    });

    console.log("postViolation", request);
    request.subscribe((data: any) => {
      console.log("postViolation", data);
    });
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(each => each.unsubscribe());
  }
}
